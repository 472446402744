import * as React from 'react';
import { IntroHome, Page } from '@mq/gatsby-components';

import IndexLayout from '../layouts';
import { graphql } from 'gatsby';
import ComponentBlocks from '../containers/ComponentBlocks';

import {
  HomePage,
  HomePage_craft_homePage_Craft_homePage_homePage_Entry as HomeEntry,
} from '@mq/generated-types/dist/HomePage';

import { isQA, getMostRecentDraft } from '../utils/misc';

interface CraftData {
  data: HomePage;
}

const IndexPage: React.FC<CraftData> = ({ data }) => {
  const { homePage, drafts } = data.craft;

  let typedHomePage = homePage as HomeEntry;
  const typedDrafts = drafts as HomeEntry[];

  if (isQA && typedDrafts.length) {
    typedHomePage = getMostRecentDraft(typedDrafts);
  }

  // Checks for query paramter "?r=1", (This query parameter is sent with redirect from marquee.gs.com)
  // If the query parameter is present and user service returns 200 We redirect user to Marquee Home page (/s/)
  // If the query parameter is presnt and user service returns non 200 we strip query parameter and load welcome page
  // If the query parameter is absent we load the welcome page
  const [ready, setReady] = React.useState(false);
  React.useEffect(() => {
    const check = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      if (urlSearchParams.get('r') === '1') {
        try {
          const res = await fetch('/v1/users/self');
          if (res.status === 200) {
            window.location.assign(`/s/`);
            return;
          } else {
            window.history.replaceState(null, '', window.location.pathname);
          }
        } catch (err) {}
      }

      setReady(true);
    };
    void check();
  }, [setReady]);

  if (!ready) {
    return null;
  }

  return (
    <IndexLayout>
      <Page>
        <IntroHome craft={typedHomePage} />
        <ComponentBlocks pageBlocks={typedHomePage.pageBlocks} />
      </Page>
    </IndexLayout>
  );
};

export default IndexPage;

export const HomePageEntryFragment = graphql`
  fragment HomePageEntry on Craft_homePage_homePage_Entry {
    id
    sourceId
    dateUpdated
    description
    primaryButtonLabel
    primaryButtonLink
    secondaryButtonLabel
    secondaryButtonLink
    logo {
      ...Asset
    }
    image {
      ...Asset
    }
    video {
      ...Asset
    }

    pageBlocks {
      ...PageBlocks
    }
  }
`;

export const HomePageQuery = graphql`
  query HomePage {
    craft {
      homePage: entry(slug: "home-page") {
        ...HomePageEntry
      }
      drafts: entries(slug: "home-page", drafts: true) {
        ...HomePageEntry
      }
    }
  }
`;
