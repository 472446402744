import React from 'react';
import { graphql } from 'gatsby';

import {
  CardGallery,
  CardThreeColumn,
  CardTwoColumn,
  Collateral,
  CTA,
  FeatureOverUnder,
  FeatureSideBySide,
  Files,
  Grid,
  IntroPage,
  IntroSection,
  ProductInfo,
  Questions,
  Stats,
  TabbedHorizontal,
  TabbedVertical,
  Table,
  Teaser,
  Video,
  useQAAudience,
} from '@mq/gatsby-components';

import { PageBlocks } from '@mq/generated-types/dist/PageBlocks';
import { CardGalleryBlock_cards_Craft_cardGallery_cardGallery_Entry } from '@mq/generated-types/dist/CardGalleryBlock';
import { isCorrectAudience, isQA } from '../utils/misc';

interface Props {
  pageBlocks: (PageBlocks | null)[] | null;
}

export const PageBlocksFragment = graphql`
  fragment PageBlocks on Craft_pageBlocks_MatrixField {
    __typename
    ...CardGalleryBlock
    ...CardThreeColumnBlock
    ...CardTwoColumnBlock
    ...CollateralBlock
    ...CTABlock
    ...FeatureOverUnderBlock
    ...FeatureSideBySideBlock
    ...FilesBlock
    ...GridBlock
    ...IntroPageBlock
    ...IntroSectionBlock
    ...ProductInfoBlock
    ...StatsBlock
    ...TableBlock
    ...TeaserBlock
    ...VideoBlock
    ...TabbedVerticalBlock
    ...TabbedHorizontalBlock
    ...QuestionsBlock
  }
`;

// const isCorrectAudience = (audiences: (string | null)[] | null) => {
//   return audiences?.includes(AUDIENCE_LEVEL.toLowerCase());
// };

const ComponentBlocks = ({ pageBlocks }: Props): JSX.Element | null => {
  const audience = useQAAudience();
  return (
    <>
      {pageBlocks?.map((block) => {
        // if (!block || !isCorrectAudience(block.audience)) return null;
        if (isQA) {
          if (!block || !block.audience?.includes(audience.toLowerCase()))
            return null;
        } else if (!block || !isCorrectAudience(block.audience)) return null;

        switch (block?.__typename) {
          case 'Craft_pageBlocks_introPage_BlockType': {
            return <IntroPage key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_introSection_BlockType': {
            return <IntroSection key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_productInfo_BlockType': {
            return <ProductInfo key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_teaser_BlockType': {
            return <Teaser key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_cta_BlockType': {
            return <CTA key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_featureSideBySide_BlockType': {
            return <FeatureSideBySide key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_table_BlockType': {
            return <Table key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_video_BlockType': {
            return <Video key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_files_BlockType': {
            return (
              <Files
                id={block.id || ''}
                key={block.id || ''}
                text={block.blockTitle}
                subText={block.description}
                assets={block.files}
              />
            );
          }
          case 'Craft_pageBlocks_introSection_BlockType': {
            return block.id ? (
              <div id={block.id} key={block.id || ''}>
                <div>{block.blockTitle}</div>
                <div>{block.description}</div>
              </div>
            ) : null;
          }
          case 'Craft_pageBlocks_grid_BlockType': {
            return block.squares ? (
              <Grid key={block.id || ''} craft={block} />
            ) : null;
          }
          case 'Craft_pageBlocks_featureOverUnder_BlockType': {
            return <FeatureOverUnder key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_stats_BlockType': {
            return <Stats key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_collateral_BlockType': {
            return <Collateral key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_tabbedHorizontal_BlockType': {
            return <TabbedHorizontal key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_tabbedVertical_BlockType': {
            return <TabbedVertical key={block.id || ''} block={block} />;
          }
          case 'Craft_pageBlocks_cardGallery_BlockType': {
            return (
              <CardGallery
                key={block.id || ''}
                craft={{
                  ...block,
                  cards:
                    block.cards as CardGalleryBlock_cards_Craft_cardGallery_cardGallery_Entry[],
                }}
              />
            );
          }
          case 'Craft_pageBlocks_cardThreeColumn_BlockType': {
            return <CardThreeColumn key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_cardTwoColumn_BlockType': {
            return <CardTwoColumn key={block.id || ''} craft={block} />;
          }
          case 'Craft_pageBlocks_question_BlockType': {
            return <Questions key={block.id || ''} craft={block} />;
          }
          default:
            return null;
        }
      })}
    </>
  );
};

export default ComponentBlocks;
